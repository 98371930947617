import '../components/MuiClassNameSetup';
import '../styles/globals.css'
import '../styles/menu.css'
import type { AppProps } from 'next/app'
import ModalLayout from "../components/modalLayout/ModalLayout";

function MyApp({ Component, pageProps }: AppProps) {

  return (
      <ModalLayout>
        <Component {...pageProps} />
      </ModalLayout>
  )
}

export default MyApp
