import {MenuItem} from "../PropsTypes";

export const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const menuList: MenuItem[] = [
    {link: '/pass', title: 'Pass'},
    {link: '/pay', title: 'Pay'},
    {link: '/security', title: 'Security'},
    {link: '/loyalty', title: 'Loyalty'},
    {link: '/about', title: 'О нас'},
]

export const footerList = [
    {
        url: '/',
        nameUrl: 'Face 2'
    },
    {
        url: '/pass',
        nameUrl: '<span>Face 2</span> Pass'
    },
    {
        url: '/pay',
        nameUrl: '<span>Face 2</span> Pay'
    },
    {
        url: '/security',
        nameUrl: '<span>Face 2</span> Security'
    },
    {
        url: '/loyalty',
        nameUrl: '<span>Face 2</span> Loyalty'
    },
    {
        url: '/about',
        nameUrl: 'О нас'
    },
];

export const headerNavList = [
    {
        url: '/pass',
        nameUrl: 'Pass'
    },
    {
        url: '/pay',
        nameUrl: 'Pay'
    },
    {
        url: '/security',
        nameUrl: 'Security'
    },
    {
        url: '/loyalty',
        nameUrl: 'Loyalty'
    },
    {
        url: '/about',
        nameUrl: 'О нас'
    },
];